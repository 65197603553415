:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;    
     /*General*/
    --container-max-width: 1400px;
    --fixed-header-height: 80px; 
    /* Slick */
    --slick-slider-height: 100vh;
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #537fb9;
    --accent-color2: radial-gradient(ellipse at center,  #9cd8d6 0%,#87cac8 100%);
    --effect-color: #222;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;    
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;   
    /* Fonts */
    --base-font-family: Raleway, sans-serif;
    --alternative-font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
}


@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Karla/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Roboto Slab/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
@import "/fonts/Raleway/all.css"; 
/*@import "/cssmodules/animate.css";*/
.show-slide2.show-slide2.show-slide2 {min-width: 38px; }
.show-slide.show-slide.show-slide {min-width:38px; }
 

/* General sections
---------------------------------------------------------*/
.section {
    height:auto;
    min-height:100vh;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
}

body {
    background: url('/theme/intendit/graphics/bg1.png');
}

.maincontent {
    background: #fff;
}

body.fixed {
    border-top:62px solid;
}

body.fixed header {
    min-height:62px;
}

.white {
    background:white;
}

.section .inner-content.white {
    padding: 2em;
}

.section h2 {
    display:block;
    text-align:center;
}

.lightblue {
    background:#A0B1C9;
}

/* Header
---------------------------------------------------------*/
header {
    background: #fff;
    text-transform:none;
    padding:var(--padding);
    z-index:10;
}

.homepage header {
    background: rgba(255,255,255,.8);
    position:fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
}

.homepage .resize-header {
    transition: background  0.8s ease;
    background: #fff;
} 
.homepage .resize-header li a {
    color:black;
}

.homepage .resize-header .menu li a:hover, .homepage .resize-header .menu .active a {
    color:white;
}

.menu li {
    margin:0;
}

.menu li a {
    padding: var(--padding);
    color: #222;
    text-transform: uppercase;
    letter-spacing: 0.18rem;
}

.menu li a:hover, .menu .active a {
    color: var(--accent-color) !important;
}

.menu .dropdown ul {
    background: #fff;
    z-index: 100;
    border-bottom: none;
    font-size: .8rem;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--padding);
    color: #000;
    border-right:none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    color: var(--accent-color);
}

.menu .sub-menu-items li a {
    margin:0;
   
}

ul.sub-menu-items {
    margin-top: 0 !important;
}

.sub-menu-items li a {
    border-bottom: none;
}



@media (min-width: var(--breakpoint-menu)) {
    header .menu .sub-menu-items .sub-menu-items {
        display:none;
    }
}

/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
}

.submenu ul li a {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

.submenu ul ul li {
    border-bottom: none;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.logo h1 {
    margin:0;
    font-size:0;
}


/* Section slider
---------------------------------------------------------*/


.slick {
    position: relative;
    margin-bottom: 0;
}

.section-slider .slick-track, .section.reference .slick-track {
    display: flex;
    flex-direction: row;
}   

.homepage .section-slider .slick,
.homepage .section-slider .slick-slide,
.homepage .section-slider .slick-substitute > div {
    height:100%;
    height: var(--slick-slider-height);
}

.homepage .section-slider .slick-slide,
.homepage .section-slider .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

/*.homepage .section-slider .slick-slide:after,
.homepage .section-slider .slick-substitute > div:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/theme/intendit/graphics/dot.png');
}*/

.slick-slider {
    margin:0 auto;
    position: relative;
}

.homepage .section-slider .slick-slide,
.homepage .section-slider .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
    filter: grayscale(.5);
} 

.slick-title {
    width:100%;
    position:relative;
    z-index:10;
    text-align:center;
    color: white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title .container.lgr {
    max-width:80%;
    width: 100%;
    margin-top: 30vh;
    
}

.slick-title h2 {
    display:block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0.5em;

}

.section-slider .small {
    max-width:500px;
    width:100%;
    margin:0 auto;
}

.section-slider .small svg.fa-icon {
    margin-top:2em;
    fill: currentColor;
    width:52px;
    height:52px;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--accent-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background: var(--accent-color);
    display:block;
}

.slick-dots li {
    border: var(--accent-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Section text 
---------------------------------------------------------*/

.section.text {
    background-attachment: fixed !important;
}


.section.text h2 {
    text-align:left;
}

.section.text .content .contentDiv {
    padding: 0 2em;
    background: #fff;
}


/* Section Citat Slick 
--------------------------------------------------------*/

.section.citat{
    background-attachment: fixed !important;
    text-align: center;
}



/* Section text and boxes and news
---------------------------------------------------------*/

.teaser {
    background-image: url('/theme/intendit/graphics/quote.png');
    background-position: left top; 
    background-repeat: no-repeat;
}

.teaser-inner {
    color: var(--accent-color);
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--alternative-font-family);
}

.teaser-inner.container {
    max-width: 80%;
    padding: 3rem;
}

.boxes .content {
    text-align:center;
}

h2.contenttitle {
    color: var(--effect-color);
}

/* boxar */

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #777;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    z-index: 3;
    font-weight: 400;
}



.pop-img {
    position:relative;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center center;
    display:flex;
    align-items:center;
}

.boxwrapper {
    align-items: flex-start;
    box-shadow: 0 0 0 1px #eee;
    height: 100%;
}

.boxwrapper h2 {
    z-index: 3;
    font-weight: 400;
    color: #777;
    font-size: 1.8rem;
    margin-top: 20px;
    letter-spacing: 0.10rem;
}

.box-img {
    max-width: 300px;
    height: auto;
}

.box-inner {
    background: #f5f5f5;
    min-height:200px;
}


.news .box-inner {
    min-height: 180px !Important;
}

.box-inner ul li {

}

.box-inner ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: var(--text-color);
}

.boxbutton {
    color: var(--accent-color);
    font-family: var(--alternative-font-family);
}

.boxbutton {
    background: var(--effect-color) !important;
    /*width: 100%;*/
    color: #fff;
    padding: 1rem;
    text-align: center;
    transition: ease .5s;
    border-radius: 0;
}

.news-section .boxbutton {
    width: 100%;
}

.boxbutton:hover {
    background: var(--accent-color) !important;
    color: #fff;
}

.showmore {
    height:0;
    transition: opacity 1s ease-out;
    opacity: 0; 
    display: none;
}

.showmore.more {
    display: block;
    height:auto;
    transition: opacity 1s ease-out;
    opacity: 1; 
    margin-top:1em;
}


.innerdiv_after {
    background: var(--accent-color2);
    color: #fff;
}

.innerdiv_after h2 {
    color: #fff;
}

.news-section {
    min-height: 60vh;
}

.publishdate {
    font-size: .8em;
    color: #999;
}

.boxwrapper.news h3 {
    text-align: center;
    padding: 1rem;
}

.startnews {
    background: var(--accent-color2);
    color: #fff;
}

.startnews h1, .startnews h2, .startnews h3, .startnews h4 {
    color: #fff !important;
    padding: 1rem 0 0 0;
}

.news {
    text-align: left;
}

.news-item {
    margin: 1rem 1rem .5rem 1rem;
    text-align: left;
}

.news-item h4 a {
    color: var(--effect-color);
    font-weight: 500;
    margin: .5rem 0 0 0;
}

.news-item h4 a {
    text-decoration: none;
}

.news-item a {
    color: #fff;
}

.news-item a:hover {
    color: #ccc;
}

.news img {
    max-height: 166px;
}

/* nyhetsarkiv */

.Nyheter .maincontent{
    margin-top: 10px;
}

.newspage-item {
    margin-bottom: 1rem;
    box-shadow:0 0 0 1px #ccc;
}

.newspage-item .inner {
    padding: 1rem;
}

.newsitem-readmore-button {
    background: var(--main-color);
    color: #222;
    padding: .3rem;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;
}

.newsitem-readmore-button a {
    text-decoration: none;
}

/* knapp till den valbara nyhetsrutan på startsidan */
.readmore-button2 {
    background: var(--effect-color);
    display: block;
    text-decoration: none;
    font-size: .8rem;
    padding: .5rem;
}

a .readmore-button2 {
    text-align: center;
    text-decoration: none;
}

.news-item a {
    text-decoration: none;
}

/* references
---------------------------------------------------------*/
.section.reference .content {
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
    background: #fff;    
}

.section.reference .content h2 {
    margin: 2rem 0;
}

.section.reference .content .slick-dots {
    position:relative;
}

.section.reference .content .small {
    max-width:600px;
    width:100%;
    margin:0 auto;
    color:white;
}

.section.reference .content .small .citat {
    font-style:italic;
    font-size:1.3rem;
    padding-bottom:1em;
    border-bottom:#eee solid 1px;
}

.section.reference .content .small .from {
    margin-top:1em;
}


.section.reference img {
    filter: grayscale(.5);
    max-width: 140px;
    height: auto;
}

.section.reference img:hover {
    filter: grayscale(0);
    transition: ease .65s
}

/* PRODUKTER 
---------------------------------------------------------*/

/*--- SlideOut-Knappen ----> Webinar ----*/


.slideoutwrapper h3 {
    text-align: center;
    padding: 0;
}

.slideoutwrapper h3 a {
    text-decoration: none;    
}

#slideout {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 70%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-100%) translateY(-50%);
    max-width: auto;
    height: auto;
}

#slideout.on {
  transform: translateX(-0%) translateY(-50%);
}

.show-slide {
    position: absolute;
    top: 40%;
    right: 0px;
    z-index: 10000;
    transform: translateX(100%) translateY(-50%);
    background: var(--effect-color);
    color:#fff;
    border:none;
    border-radius:0px;
    margin-right: 5px;
    padding: 10px;
    letter-spacing: 0.10rem;
    border: 1px solid #222;
}

.show-slide:hover {
    border: 1px solid #222;
}

.show-slide h3 {
    writing-mode: vertical-lr;
    color: #fff;
}

.slideoutwrapper {
    padding:2em;
    max-height:100vh;
    max-width: 520px;
    overflow: auto;
}

/*--- SlideOut-knapp 2 ----> Produktinfo ----*/


.slideoutwrapper2 h3 {
    text-align: center;

}

.slideoutwrapper2 h3 a {
    text-decoration: none;    
}

#slideout2 {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 40%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-100%) translateY(-50%);
}

#slideout2.on {
  transform: translateX(-0%) translateY(-50%);
}

.show-slide2 {
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10000;
    transform: translateX(100%) translateY(-50%);
    background: var(--accent-color2);
    color:#fff;
    border:none;
    border-radius:0px;
    margin-right: 5px;
    padding: 10px;
    letter-spacing: 0.10rem;
    border: 1px solid var(--accent-color2);
}

.show-slide2:hover {
    background: var(--accent-color2);
    border: 1px solid #8FCFCD;
}

.show-slide2 h3 {
    writing-mode: vertical-lr;
    color: #fff;
}

.slideoutwrapper2 {
    padding:2em;
    max-height:100vh;
    max-width: 520px;
    overflow: auto;
}
/*****/

.news-block {
    top: 20em;
}

.news-block .buttonfbreadmore {
    padding: 8px 12px;
    color: #000000;
    background: #FFFFFF;
    text-decoration: none;
    border-radius: 3px;
    display: inline-block;
    margin: 0;
    text-align: center;
    border: 1px solid;
}

.news-block img {
    display: block;
    margin-top: 4vh;
}


.produkt-item {
    background: var(--accent-color2);
}

.produkt-item h2 {
    padding-left: 0;
    margin-left: 0;  
    color: #fff;
}

 /*{
    background: var(--effect-color);
    color: #fff;
    padding-left: 0;
    margin-left: 0;
}*/

h2.product-ref, .produkt h2{
    background: var(--accent-color2);
    color: #fff;
    padding: 1.2rem;
    text-align: center;
    margin-top: 3rem;
}


a .readmore-button {
    padding: .5rem 3rem;
    float: right;
    background: var(--effect-color);
    color: #fff;
    font-family: var(--alternative-font-family);
    text-align: center;
    transition: .5s ease;
}

a .readmore-button:hover {
    background: var(--accent-color);
}

.webinar {
    background: var(--accent-color2);
    color: #fff;
    border-radius: 0 10px 10px 0;
}

.webinar-inner {
    padding: 2rem;
}

.webinar h1, .webinar h2, .webinar h3, .webinar h4 {
    color: #fff !important;
/*    padding: 1rem 0 0 1rem; */
}

.productintro {
    color: var(--accent-color);
    font-size: 1.2rem;
    font-family: var(--alternative-font-family);
    background-image: url('/theme/intendit/graphics/quote.png');
    background-position: left top; 
    background-repeat: no-repeat;
    text-align: center;
}

.productintro-inner.container {
    max-width: 90%;
    padding: 3rem;
}

.productsecond {
    background: var(--main-color);
    padding: 1rem;
}

.productsecond h2 {
    background: none;
    color: var(--accent-color);
    padding: 0;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: left;
}

.productsecond h2, .productsecond h3 {
    padding: 0 0 0 0rem;
    margin-bottom: 0;
}

.productimage {
    text-align: center;
    margin: 0 auto;
}

/* Filetrain */
.demo-download {
    background: var(--accent-color2);
    border: none;
   padding: 1rem;
}

.demo-download:hover {
    border: none;
    color: #fff;
}

.demo-download a {
    text-decoration: none;
    font-family: var(--alternative-font-family);
    text-shadow: 1px 1px 0 #777;
    font-size: 1.4rem;
}

.demo-download a:hover {
    color: #fff;
}

/* TJÄNSTER
--------------------------------------------------------*/
.tjanst-item {
    background: var(--accent-color2);
}

.tjanst-item h2 {
    padding-left: 0;
    margin-left: 0;  
    color: #fff;
}

.tjanst-item .inner {
    padding: 1rem;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 10px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--accent-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.contact-section {
    background: #fff;
    padding: 2rem;
}

h2.contact-section {
    text-align: center;
}

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
    
}

.contact-block-l, .contact-block-2 {
    padding: 2rem;
}

.contact-block-2 > div {

    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block>div a {
    text-decoration:none;
}



.art-img {
    margin-right:0;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
    text-align: center;
    margin-top: 1rem;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
    text-decoration: none;
}

.intenditfooter >* {
    margin: var(--margin);
}

footer .contactinfo p {
    margin:0;
}

footer {
    padding-top: 2rem;
    background: #fff;
}

footer a {
    text-decoration: none;
}

/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin: 0;
}

/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit], .button, button {
    margin:0;
    background: var(--accent-color);
    border: var(--accent-color) solid 1px;
    color:white;
    transition: .5s ease;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover, button:hover {
    background: var(--effect-color);
    border: var(--accent-color) solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    margin:0 auto;
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    color: var(--accent-color);
    margin: 0 0 0.3em 0;
}

h1{
    font-size: 2.4rem;
    color: var(--effect-color);
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--accent-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}


/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}


.boltform input, .boltform textarea {
    width: 100%;
    color: #222;
}

.boltform button {
    border: none;
    margin: 0;
}

.boltform button:hover {

}

.boltform .boltforms-row label.required:after {
    content: " *";
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: #222;
    color: #fff;
    transition: 0.2s ease-in 0s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--accent-color);
    border: none;
    color: #fff;
}



/* Media Queries
---------------------------------------------------------*/

@media (max-width: 1500px) {
    .submenu {
/*      padding-left: 30px;*/
    }
}

 
@media (max-width: var(--breakpoint-menu)) {
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }

    .menu.menu li a:hover {
        color: #fff !important;
    }
    
    .menu .dropdown ul li a {
        font-size: .8rem;
    }

    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }

    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
    }

    .menu .active a {
        color: #fff !important;
    }
    
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid;
    }   
        .section {
        height:auto;
    }
    .resize-header #menu-icon:before, .resize-header #menu-icon:after, .resize-header #menu-icon span {
        border-color:black;
    }
    .homepage .resize-header li a {
        color:white;
    }
}

@media (max-width: var(--breakpoint-m)) {
    
    .productintro-inner.container {
        max-width: 100%;
        padding: 0;
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    .teaser-inner.container {
        max-width: 100%;
    }
    
    .teaser {
        background-image: none !important;
    }
    
    footer {
        text-align: center;
    }
    
    .productintro-inner.container {
        max-width: 100%;
        padding: 0;
    }
}

@media (max-width: var(--breakpoint-xs)) {
    footer .container {
        text-align:center;
    }
}


.startbox .inner svg.fa-icon {
    fill:#627C9E;
    width:32px;
    height:32px;
}

.startbox-inner.block-rub h3 {
    color:white;
    letter-spacing:0;
    padding:0.5em 0 0 0;
    font-size:1.6rem;
    font-weight:400;
    
}

.slick:not(.slick-slider) > div:not(:first-child) {
    display: none;
}

.slick:not(.slick-slider) > div {
    height: 100%;
    min-height: 100vh;
}



@media (max-height: 800px) {
    .section {
        height: auto;
    }
}

.test {
    display: none;
}

.more .excerpt {
    display: none;
}

.more ~ .twelve {
    display: none;
}

.more .test {
    display: block;
}

.localeswitcher.active {
    display:none;
}
